
$(window).on("load", function () {

    if(document.querySelector("#total-supplier-members")) {

        var $primary = '#3399fe';
        var $danger = '#EA5455';
        var $warning = '#FF9F43';
        var $info = '#28c76f';
        var $primary_light = '#33D4FE';
        var $warning_light = '#FFC085';
        var $danger_light = '#f29292';
        var $info_light = '#1edec5';
        var $strok_color = '#b9c3cd';
        var $label_color = '#e7eef7';
        var $white = '#fff';


        // Subscribers Gained Chart starts //
        // ----------------------------------

        var gainedChartoptions = {
            chart: {
                height: 100,
                type: 'area',
                toolbar: {
                    show: false,
                },
                sparkline: {
                    enabled: true
                },
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
            },
            colors: [$primary],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2.5
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.9,
                    opacityFrom: 0.7,
                    opacityTo: 0.5,
                    stops: [0, 80, 100]
                }
            },
            series: [{
                name: 'Subscribers',
                data: [28, 40, 36, 52, 38, 60, 55]
            }],

            xaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                }
            },
            yaxis: [{
                y: 0,
                offsetX: 0,
                offsetY: 0,
                padding: {left: 0, right: 0},
            }],
            tooltip: {
                x: {show: false}
            },
        }

        var gainedChart = new ApexCharts(
            document.querySelector("#total-supplier-members"),
            gainedChartoptions
        );

        gainedChart.render();

        // Subscribers Gained Chart ends //
    }


});
