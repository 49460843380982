
$(window).on("load", function () {

    if(document.querySelector("#total-groups")) {

        var $primary = '#3399fe';
        var $danger = '#EA5455';
        var $warning = '#FF9F43';
        var $info = '#28c76f';
        var $primary_light = '#33D4FE';
        var $warning_light = '#FFC085';
        var $danger_light = '#f29292';
        var $info_light = '#1edec5';
        var $strok_color = '#b9c3cd';
        var $label_color = '#e7eef7';
        var $white = '#fff';

        let totalgroups_count = window.App.totalgroups_count !== undefined ? JSON.parse(window.App.totalgroups_count) : ''

        const month = [];
        const data = [];


        Object.keys(totalgroups_count).forEach(function (key) {
            month.push(key);
            data.push(totalgroups_count[key])
        })

        var options = {
            series: [{
                name: 'Total Groups this Month',
                data: data
            }],
            chart: {
                height: 180,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'month',
                categories: month
            },

        };

        var chart = new ApexCharts(document.querySelector("#total-groups"), options);
        chart.render();
    }
});
