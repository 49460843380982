$(document).ready(function () {

    if (document.querySelector("#pie-chart")) {

        var $primary = '#f37233',
            $success = '#FBEBE3',
            $danger = '#EA5455',
            $warning = '#FF9F43',
            $info = '#00cfe8',
            $label_color_light = '#dae1e7';

        var themeColors = [$primary, $success, $danger, $warning, $info];

        let total_inactive = window.App.total_inactive !== undefined ? JSON.parse(window.App.total_inactive) : ''
        let total_active = window.App.total_active !== undefined ? JSON.parse(window.App.total_active) : ''

        var pieChartOptions = {
            chart: {
                type: 'pie',
                height: 250
            },
            colors: themeColors,
            labels: ['Active', 'Inactive'],
            series: [total_active, total_inactive],
            legend: {
                position: 'bottom',
                itemMargin: {
                    horizontal: 2
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
        var pieChart = new ApexCharts(
            document.querySelector("#pie-chart"),
            pieChartOptions
        );
        pieChart.render();
    }
});
