
$(window).on("load", function () {

    if(document.querySelector("#total-members-lost")) {

        var $primary = '#3399fe';
        var $danger = '#EA5455';
        var $warning = '#FF9F43';
        var $info = '#28c76f';
        var $primary_light = '#33D4FE';
        var $warning_light = '#FFC085';
        var $danger_light = '#f29292';
        var $info_light = '#1edec5';
        var $strok_color = '#b9c3cd';
        var $label_color = '#e7eef7';
        var $white = '#fff';


        var options = {
            series: [{
                name: 'Total Members Lost',
                data: [20, 10, 30, 5, 6, 20, 70]
            }],
            chart: {
                height: 250,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'month',
                categories: ["01/21", "02/21", "03/21", "04/21", "05/21", "06/21", "07/21"]
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        };

        var chart = new ApexCharts(document.querySelector("#total-members-lost"), options);
        chart.render();
    }
});
