$(document).ready(function () {

    if(document.querySelector("#total-revenue")) {

        var $primary = '#28C76F',
            $success = '#28C76F',
            $danger = '#EA5455',
            $warning = '#FF9F43',
            $info = '#00cfe8',
            $label_color_light = '#dae1e7';

        var themeColors = [$primary, $success, $danger, $warning, $info];

        let revenue_count = window.App.revenue_count !== undefined ? JSON.parse(window.App.revenue_count) : ''

        const month = [];
        const data = [];


        Object.keys(revenue_count).forEach(function (key) {
            month.push(key);
            data.push(revenue_count[key])
        })

        let yaxis_opposite = ''

        var lineAreaOptions = {
            chart: {
                height: 190,
                type: 'area',
            },
            colors: themeColors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            series: [{
                name: 'Total Revenue',
                data: data
            }],
            legend: {
                offsetY: -10
            },
            xaxis: {
                type: 'months',
                categories: month

            },
            yaxis: {
                opposite: yaxis_opposite
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            }
        }
        var lineAreaChart = new ApexCharts(
            document.querySelector("#total-revenue"),
            lineAreaOptions
        );
        lineAreaChart.render();
    }
});
