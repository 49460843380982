
$(window).on("load", function () {

    if(document.querySelector("#total-group-members")) {

        var $primary = '#3399fe';
        var $danger = '#EA5455';
        var $warning = '#FF9F43';
        var $info = '#28c76f';
        var $primary_light = '#33D4FE';
        var $warning_light = '#FFC085';
        var $danger_light = '#f29292';
        var $info_light = '#1edec5';
        var $strok_color = '#b9c3cd';
        var $label_color = '#e7eef7';
        var $white = '#fff';

        let totalgroupfollowers_count = window.App.totalgroupfollowers_count !== undefined ? JSON.parse(window.App.totalgroupfollowers_count) : ''

        const month = [];
        const data = [];


        Object.keys(totalgroupfollowers_count).forEach(function (key) {
            month.push(key);
            data.push(totalgroupfollowers_count[key])
        })

        var options = {
            series: [{
                name: 'Total Number of Users in Groups',
                data: data
            }],
            chart: {
                height: 180,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'month',
                categories: month
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        };

        var chart = new ApexCharts(document.querySelector("#total-group-members"), options);
        chart.render();

    }

});
