
$(window).on("load", function () {

    if(document.querySelector("#new-user")) {

        var $primary = '#3399fe';
        var $danger = '#EA5455';
        var $warning = '#FF9F43';
        var $info = '#28c76f';
        var $primary_light = '#33D4FE';
        var $warning_light = '#FFC085';
        var $danger_light = '#f29292';
        var $info_light = '#1edec5';
        var $strok_color = '#b9c3cd';
        var $label_color = '#e7eef7';
        var $white = '#fff';


        // Avg Session Chart Starts
        // ----------------------------------

        var sessionChartoptions = {
            chart: {
                type: 'bar',
                height: 200,
                sparkline: {enabled: true},
                toolbar: {show: false},
            },
            states: {
                hover: {
                    filter: 'none'
                }
            },
            colors: [$label_color, $label_color, $primary, $label_color, $label_color, $label_color],
            series: [{
                name: 'New Subscribers',
                data: [75, 125, 225, 175, 125, 75, 25]
            }],
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0
                }
            },

            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                    endingShape: 'rounded'
                }
            },
            tooltip: {
                x: {show: false}
            },
            xaxis: {
                type: 'numeric',
            }
        }

        var sessionChart = new ApexCharts(
            document.querySelector("#new-user"),
            sessionChartoptions
        );

        sessionChart.render();

        // Avg Session Chart ends //
    }

});
