$(document).ready(function () {

    function createSlug(text){
        return '/'+text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }

    //as the user types in the title, the slug is updated
    $('#title').on('keyup', function(e) {
        let placement = $('#content_placement').children("option:selected").val();
        if (placement == 'member' || placement == 'admin'){
            $('#slug').val('/'+placement+createSlug(e.target.value))
        }
        else {
            $('#slug').val(createSlug(e.target.value))
        }

    });

    //changes the slug when content placement is selected
    $('#content_placement').on('change', function(e){
        let placement = e.target.selectedOptions[0].value;
        if (placement == 'member' || placement == 'admin'){
            $('#slug').val('/'+placement+$('#slug').val());
        }
    });

    //set the field with the icon picket dropdown
    $('#icon').iconpicker();
});
