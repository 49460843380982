
$(document).ready(function(){
    document.addEventListener('keyup',function(e){
        FormKeyUpEvent(e);
    });

    $('.select2').on('select2:selecting', function(e) {
        Select2OnChangeEvent(e);
    });

    document.addEventListener('change',function(e){
        if (e.target.name !== 'terms') FormKeyUpEvent(e);

        if (e.target.name === 'terms') TermsEvent(e);
    });
});
function FormKeyUpEvent(e)
{
    if (e.target.previousElementSibling != null && e.target.previousElementSibling.classList.length > 0 && e.target.previousElementSibling.classList.contains('required')){
        if(e.target.value.length > 0){
            e.target.classList.remove('is-invalid')
            e.target.classList.add('is-valid')

            if (e.target.previousElementSibling.classList.contains('text-danger')) {
                e.target.previousElementSibling.classList.remove('text-danger');
                e.target.nextElementSibling.lastElementChild.classList.remove('text-danger');
                e.target.nextElementSibling.lastElementChild.innerHTML = '';
            }
        }
        else {
            e.target.classList.remove('is-valid')
            e.target.classList.add('is-invalid')
            e.target.previousElementSibling.classList.add('text-danger');
        }
    }
}

function Select2OnChangeEvent(e)
{
    if (e.target.parentNode.firstChild.classList.length > 0 && e.target.parentNode.firstChild.classList.contains('required')){
        if(e.params.args.data.id !== ''){
            e.target.classList.remove('is-invalid')
            e.target.classList.add('is-valid')
            e.target.previousElementSibling.previousElementSibling.classList.remove('text-danger');
            e.target.nextElementSibling.nextElementSibling.lastChild.classList.remove('text-danger');
            e.target.nextElementSibling.nextElementSibling.lastChild.innerHTML = '';
        }
    }
}

function TermsEvent(e)
{
    if (e.target.value === 'on' && e.target.parentNode.parentNode.nextElementSibling){
        e.target.parentNode.parentNode.nextElementSibling.innerHTML = '';
    }
}
