$(document).ready(function () {

    if(document.querySelector("#user-engagements")) {

        var $primary = '#FF9F43',
            $success = '#28C76F',
            $danger = '#EA5455',
            $warning = '#FF9F43',
            $info = '#00cfe8',
            $label_color_light = '#dae1e7';

        var themeColors = [$primary, $success, $danger, $warning, $info];

        // RTL Support
        var yaxis_opposite = false;
        if ($('html').data('textdirection') == 'rtl') {
            yaxis_opposite = true;
        }
        let engagement_count = window.App.engagement_count !== undefined ? JSON.parse(window.App.engagement_count) : ''

        const month = [];
        const data = [];


        Object.keys(engagement_count).forEach(function (key) {
            month.push(key);
            data.push(engagement_count[key])
        })

        // Line Chart
        // ----------------------------------
        var lineChartOptions = {
            chart: {
                height: 250,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            colors: themeColors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            series: [{
                name: "Total Engagements",
                data: data,
            }],

            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: month,
            },
            yaxis: {
                tickAmount: 5,
                opposite: yaxis_opposite
            }
        }
        var lineChart = new ApexCharts(
            document.querySelector("#user-engagements"),
            lineChartOptions
        );
        lineChart.render();
    }
});
