$(window).on("load", function () {

    if(document.querySelector("#total-group-engagements")) {

        var $primary = '#f37233';
        var $danger = '#f37233';
        var $warning = '#f37233';
        var $info = '#f37233';
        var $primary_light = '#f37233';
        var $warning_light = '#FFC085';
        var $danger_light = '#f29292';
        var $info_light = '#f37233';
        var $strok_color = '#f37233';
        var $label_color = '#e7eef7';
        var $white = '#fff';


        var themeColors = [$primary, $danger, $warning, $info];


        let totalengagementspergroup_count = window.App.totalengagementspergroup_count !== undefined ? JSON.parse(window.App.totalengagementspergroup_count) : ''

        const month = [];
        const data = [];

        Object.keys(totalengagementspergroup_count).forEach(function (key) {
            month.push(key);
            data.push(totalengagementspergroup_count[key])
        })
        // console.log('test'+totalengagementspergroup_count)

        var options = {
            colors: themeColors,
            series: [{
                name: 'Total Engagements this Month',
                data: data
            }],
            chart: {
                height: 180,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'month',
                categories: month
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        };

        var chart = new ApexCharts(document.querySelector("#total-group-engagements"), options);
        chart.render();
    }
});
