require('./admin/contentPage/index');
require('./scripts/charts/total-users');
require('./scripts/charts/total-revenue');
require('./scripts/charts/premium-members');
require('./scripts/charts/total-visitors');
require('./scripts/charts/new-users');
require('./scripts/charts/user-engagements');
require('./scripts/charts/total-group-members');
require('./scripts/charts/total-group-engagements');
require('./scripts/charts/total-event-tickets');
require('./scripts/charts/total-product-revenue');
require('./scripts/charts/total-job-applicants');
require('./scripts/charts/total-subscribers');
require('./scripts/charts/total-groups');
require('./scripts/charts/total-supplier-revenue');
require('./scripts/charts/total-supplier-members');
require('./scripts/charts/revenue-bymonth');
require('./scripts/charts/total-members-lost');
require('./scripts/charts/total-events');
require('./scripts/charts/total-resources');
require('./scripts/charts/total-videos');
require('./scripts/charts/total-suppliers');
require('./scripts/charts/total-products');
require('./scripts/charts/total-jobs');
require('./scripts/charts/total-active-premiumsubs');
require('./scripts/charts/total-individual-groupmembers');
// require('./scripts/charts/chart-apex');
require('./scripts/charts/line-chart');
require('./scripts/charts/pie-chart');
require('./FormFieldValidationDisplay');
require('./scripts/services/groups');
