$(document).ready(function () {

    if(document.querySelector("#line-chart")) {

        var $primary = '#2da0f8',
            $success = '#28C76F',
            $danger = '#EA5455',
            $warning = '#FF9F43',
            $info = '#00cfe8',
            $label_color_light = '#dae1e7';

        var themeColors = [$primary, $success, $danger, $warning, $info];

        // // RTL Support
        // var yaxis_opposite = false;
        // if ($('html').data('textdirection') == 'rtl') {
        //     yaxis_opposite = true;
        // }

        let subscriber_count = window.App.subscriber_count !== undefined ? JSON.parse(window.App.subscriber_count) : ''

        const month = [];
        const data = [];


        Object.keys(subscriber_count).forEach(function (key) {
            month.push(key);
            data.push(subscriber_count[key])
        })

        // Line Chart
        // ----------------------------------
        var lineChartOptions = {
            chart: {
                height: 235,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            colors: themeColors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            series: [
                {
                    name: "Total User Monthly",
                    data: data
                }
            ],

            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: month
            }

        }
        var lineChart = new ApexCharts(
            document.querySelector("#line-chart"),
            lineChartOptions
        );
        lineChart.render();
    }
});
